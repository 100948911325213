//rowClickStatusChange(row,isSelect) 点击行之后，向外抛出当前行是否选中事件
import GridTableUtil,{IGridTableDataObj} from "@/components/base_comp/gridTable/gridTableUtil";
import {ref, getCurrentInstance, reactive, nextTick, toRefs, onMounted, onBeforeMount, onUpdated, inject, defineComponent, watch} from 'vue';
import Sortable from "sortablejs";
const GridTableHelper = defineComponent({
    name: "GridTable",
    props: {
        queryParam: {//查询条件
            type: Object,
            default:()=>{return{}}
        },
        gridLoaded:{type: Function, default: async (options:any) => {}},
    },
    //以下这个自定义指令的作用是：当表格出现横向滚动条的时候，可以通过拖动表格内容来移动数据，使用方式，直接在el-table表格添加属性v-tableDrag，
    //目前我删掉它了，因为在这个版本的el-table不起作用，但是之前在其它版本中调试是通过了的。这个指令还有一个缺陷，那就是它如果使用了，
    //下面拖动表格行的功能就不能用了，而且表格不能复制当中的内容了，所以思考再三，我还是屏蔽它了
    // directives: {
    //     tableDrag: function(el) {
    //         el = el.getElementsByClassName('el-table__body-wrapper')[0];
    //         el.style.cursor = 'grab';
    //         el.onmousedown = function(event:any) {
    //             let gapX = event.clientX;
    //             let startX = el.scrollLeft;
    //             document.onmousemove = function(e) {
    //                 let x = e.clientX - gapX;
    //                 el.scrollLeft = startX - x;
    //                 el.scrollLeft = -300;
    //                 return false;
    //             };
    //
    //             document.onmouseup = function(e) {
    //                 document.onmousemove = null;
    //                 document.onmouseup = null;
    //             };
    //         };
    //     }
    // },
    setup(props:any,context){
        let gridTableOwner = inject('gridTableOwner') as any;//要使用表格，需要外界注入gridTableOwner，即使用对象是谁
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const tbRef=ref(null) as any;
        let dataObj:IGridTableDataObj=reactive<IGridTableDataObj>({
            utilInst: {} as any,
            refMap:new Map(),
            selRowId:'',//单选行的值
            tbTitle:'',
            loading: false,//是否显示正在加载
            hasSelection:false, //是否有复选框，即是否有checkbox列
            tbData: [],//表格的数据
            tbCols: {labels:[],fields:[]},//表格的列
            currentPage: 1,//当前页
            total: 0,//总条数，不是总页数
            maxTbHeight:200,//默认表格的最大高度
            params:{
                tbId:'defTbId',//表格id，一个页面有多个表格的时候，在父类作区分的时候用
                idField:'F_ID',//id名称，有时候表格的id比较特别，比如缓存重载
                gridTitle:'',//表格标题,一般情况在ListEngine那里从路由里面得到,然后传进来
                showTitle:true,//是否显示标题
                showToolBar: false,//是否显示表格工具栏
                modelMethod:'',//表格请求后台路径前缀,eg:/corp/pageData
                isShowIndexCol:true,//是否显示顺序号
                autoLoad: true,//是否自动加载
                staticData: false,//表格数据是否为静态数据，比如含有content的下拉框
                dragRow: false,//是否可以拖动表格行
                dragParent: '',//如果可以拖动，选中表格的父元素
                showRadio: false,//是否显示radio
                multiple: false,//是否允许多选
                canPage:true,//是否分页
                pagerParams: {//分页参数
                    small:false,
                    pagerCount:7,//只要5~21之间的奇数,奇数,奇数.....哎
                    pageSize: 20,
                    pageSizes: [20, 50, 100, 200],
                    layout:'total, sizes, prev, pager, next, jumper'
                },
                queryParam:{},
                columnList:[]//表格显示列，如果通过后台来返回表格的列，那么就会给该字段赋值
            },
            otherParams:{

            }
        })
        watch(() => dataObj.params.columnList,async (newValue,oldValue) => {
            if(newValue && newValue.length>0)dataObj.utilInst.setTbColumnInfoByColumnList();
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new GridTableUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            await nextTick(async()=>{ 
                Object.assign(dataObj.params,context.attrs);//不能放到nextTick外面，否则modelMethod不能成功赋值
                dataObj.utilInst.setTbColumnInfoBySlot();
                if(dataObj.params.autoLoad) await queryHandler(true,null);

                if(dataObj.params.dragRow)dragRow();//拖动表格行
                //如果说下方onUpdated那里效率有问题，那么就可以采用把整个tbody加上animate，效率好像没有影响
                await nextTick(()=>{
                    utils.AnimateUtil.gridAnimate(utils);
                })
            })
        })
        onUpdated(async ()=>{
            //表格的行一行行的从右边飞入，但是很影响效率，所以注释了。
            //没有放到上面的onMounted原因：在上面怎么都得不到tr，只能得到头部的tr，奇怪，所以放到update里面了
            // await nextTick(()=>{
            //     utils.AnimateUtil.gridRowAnimate();
            // })
        })
        const dragRow=()=>{
            let tbody:any=document.querySelector('.el-table__body-wrapper tbody');
            if(dataObj.params.dragParent){
                tbody=document.querySelector('.'+dataObj.params.dragParent+' .el-table__body-wrapper tbody');
            }
            tbody.style.cursor = 'grab';
            Sortable.create(tbody,{
                disabled: false,//是否禁用拖动，可以干掉，因为我采用的是外部传入参数dragRow来控制
                animation:150,//拖拽延时
                onEnd(ev:any){// 拖拽结束时的触发
                    if (gridTableOwner && gridTableOwner.dragRow) {
                        gridTableOwner.dragRow({ev: ev});
                    }else{
                        //很奇怪，这里不用改变tbData的顺序，但是视图已经改变了。试图改变了，但是tbData的顺却还没有变。下方注释了还是可以正常使用
                        // let newArr:Array<any>=[...dataObj.tbData];
                        // let oldIndex=ev.oldIndex;//原来的位置
                        // let newIndex=ev.newIndex;//新的位置
                        // [newArr[newIndex],newArr[oldIndex]]=[newArr[oldIndex],newArr[newIndex]];
                        // dataObj.tbData=newArr;(千万不要试图把改变之后的数组重新赋值给tbData，这样又会把改变之后的顺序变回去，达不到改变顺序的效果)
                        if (gridTableOwner && gridTableOwner.endDragRow)gridTableOwner.endDragRow({data:dataObj.tbData,ev:ev,inst:proxy});
                    }
                }
            });
        }
        //根据条件查询加载表格数据
        const queryHandler=async(isNewQuery:boolean,options:any)=>{
            dataObj.loading = true;
            if(isNewQuery)dataObj.currentPage=1;
            //表格不是静态赋值数据并且含有请求地址才发请求
            if (!dataObj.params.staticData && dataObj.params.modelMethod) {
                //在这里修改了props的queryParam，如果外层传入了queryParam，那么外层的queryParam也跟着被修改了。
                props.queryParam.pageSize = dataObj.params.pagerParams.pageSize;
                props.queryParam.currentPage = dataObj.currentPage;
                props.queryParam.canPage = context.attrs.canPage?context.attrs.canPage:dataObj.params.canPage;
                // console.log(dataObj.params.modelMethod)
                try {
                    if(options){
                        //下面这种方式并不会造成最外层queryParam改变;props.queryParam=_param这样也不会造成最外层queryParam改变，奇怪
                        //props.queryParam=Object.assign({},props.queryParam, options);
                        //但是你直接给props.queryParam添加一个属性或者修改一个属性会造成最外层queryParam改变，比如props.queryParam.abc='test1';最外层queryParam会改变
                        //用如下方式合并参数，最外层queryParam也会跟着改变（经过下面的合并，你在corpList那里打印queryParam，会发现已经改变了）
                        props.queryParam=Object.assign(props.queryParam, options);
                    }
                    let res = await utils.Api.postRequest({url: dataObj.params.modelMethod, params:props.queryParam});

                    if(res.result){
                        dataObj.loading = false;
                        dataObj.tbData = res.rows;
                        dataObj.total = res.total;
                        props.gridLoaded(res);
                    }else{
                        utils.Tools.error();
                    }
                } catch (res) {
                    console.log(res);
                }
            }

            if (dataObj.loading) dataObj.loading = false;
        }
        //重新加载表格
        const reloadGrid=async (options:any)=>{
            await queryHandler(true,options);
        }
        //为序号列赋值
        const indexMethod=(index:number)=>{
            return index+1;
        }
        //利用表格的行样式设置回调方法给表格每行的值对象加上属性rowIndex
        const rowClassName=({row, rowIndex}: {row: any,rowIndex: number})=>{
            row.rowIndex = rowIndex;
            //如果不想改变选中行背景色，可以把下方的代码去掉，在el-table上加上highlight-current-row就可以使用系统默认的处理方式了
            let selectRows=tbRef.value.store.states.selection.value;
            let index=selectRows.findIndex((row:any)=>{
                return row.rowIndex==rowIndex
            })
            if(index!=-1){
                return 'rowHighLight'
            }else{
                return '';// return 'animate__animated animate__bounceInRight';
            }
        }
        //设置表格列
        const setGridColumns=(columnList:any)=>{
            dataObj.params.columnList=columnList;
        }
        //设置表格最大高度
        const setMaxTbHeight=(maxTbHeight:number)=>{
            dataObj.maxTbHeight=maxTbHeight;
        }
        //获取当前表格数据
        const getTbData=()=>{
            return dataObj.tbData?dataObj.tbData:[];
        }
        //为表格设置数据，不用向后台发送请求
        const setTbData=(tbData:any)=>{
            dataObj.tbData = tbData;
            dataObj.total = tbData.length;
            // props.gridLoaded(tbData);
        }
        //为表格设置数据，不用向后台发送请求
        const setTbDataTotal=(total:number)=>{
            dataObj.total = total;
        }
        //清除表格数据
        const clearTbData=()=> {
            dataObj.total =0;
            dataObj.tbData.splice(0, dataObj.tbData.length)
        }
        //表格每页显示多少条记录改变事件
        const sizeChangeHandler=async (val:number)=>{
            dataObj.params.pagerParams.pageSize = val;
            await queryHandler(true,null);
        }
        //上一页、下一页、点击具体页事件
        const currentChangeHandler=async (val:number)=>{
            dataObj.currentPage = val;
            await queryHandler(false,null);
        }
        //升序/降序事件
        const sortChangeHandler=async (column:any, prop:any, order:any)=>{
            props.queryParam.orderField = column.prop;
            let orderType="asc"
            if(column.order=='descending'){
                orderType="desc";
            }
            props.queryParam.orderType =orderType;
            await queryHandler(true,null);
        }

        //点击行事件
        const rowClickHandler= (row: any)=>{
            dataObj.selRowId=row[dataObj.params.idField];
            //不清除的话，在没有checkbox的情况下，你点击一行它就会记录一行(再点击该行则取消该行)，最后selection会变得很多,然而视觉上只有最后点击的那一行颜色改变了，但是selection值却是很多行
            if(!dataObj.params.multiple)tbRef.value.clearSelection();
            //切换该行选中状态，不管有无复选框都切换一下吧
            toggleRowSelection(row);
            nextTick(()=>{
                let _row=getSelection().find((item:any)=>item[dataObj.params.idField]==row[dataObj.params.idField]);
                context.emit("rowClickStatusChange", row,_row?true:false);//向外抛出当前行是否选中状态事件
            })
        }

        //得到表格选中的行(不管有没有checkbox)
        const getSelection=()=>{
            return tbRef.value.store.states.selection.value;
        }
        //切换表格某行的选中状态
        const toggleRowSelection=(row:any)=>{
            tbRef.value.toggleRowSelection(row);
        }

        return{
            ...toRefs(dataObj),dragRow,queryHandler,reloadGrid,indexMethod,rowClassName,setGridColumns,tbRef,getTbData,setTbData,setTbDataTotal,clearTbData,
            sizeChangeHandler,currentChangeHandler,sortChangeHandler,getSelection,toggleRowSelection,
            rowClickHandler,setMaxTbHeight
        }
    }
});

export default GridTableHelper;